<template>
  <div class="type-statistic-wrapper flex">
    <d-search :searchData="searchData" @searchEvent="searchList" @exportEvent="exportList" @resetEvent="reset"></d-search>
    <div class="inline-flex contentBox">
      <d-table :tableData="tableData" :columnData="columnData" class="contentTable">
        <el-table-column prop="typeLevel2Name" label="服务项" slot="typeLevel2Name">
          <template slot-scope="{row}">
            {{row.typeLevel2Name?row.typeLevel2Name:'-'}}
          </template>
        </el-table-column>
      </d-table>
    </div>
    <d-paging :pager="pager" @change="searchList"></d-paging>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DTable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import {onDownload} from "@/utils/utils";
export default {
  name: "typeStatistic",
  components: {DPaging, DTable, DSearch},
  data() {
    return {
      searchData: {
        searchItem: [
          {label:'问题类型',value:'typeLevel',type:'commonSelect',options:this.$map.getter('tourTypes')},
          {label:'服务项',value:'typeName',type:'commonInput'},
          {label:'工单解决时间',value:'time',type:'commonDatetime'},
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
          exportBtn:true
        },
        params: {
          typeLevel:"1",
          typeName: '',
          time:['','']
        }
      },
      tableData: [],
      columnData: [
        {label: '大项', prop: 'typeName'},
        {label: '服务项', prop: 'typeLevel2Name',slot:true},
        {label: '已完成工单', prop: 'finishCount'},
        {label: '好评', prop: 'goodCount'},
        {label: '差评', prop: 'badCount'},
        {label: '一般评价', prop: 'commonCount'},
        {label: '默认评价', prop: 'normalCount'},
        {label: '平均响应时长', prop: 'avgTime'},
      ],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      }
    }
  },
  mounted(){
    this.searchList()
  },
  methods:{
    _getParams(){
      return {
        startTime:(this.searchData.params.time&&this.searchData.params.time[0])||'',
        endTime:(this.searchData.params.time&&this.searchData.params.time[1])||'',
        typeLevel:this.searchData.params.typeLevel,
        typeName:this.searchData.params.typeName,
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
      }
    },

    // 查询列表
    searchList(){
      this.$api.tour.typeStatistic(this._getParams()).then(({data})=>{
        this.tableData = data.list;
        this.pager.count = data.total;
      })
    },

    // 导出
    exportList(){
      this.$api.tour.exportTypeStatistic(this._getParams()).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },

    reset(){
      this.pager.page = 1;
      this.pager.rows = 10;
      this.searchList()
    }
  }
}
</script>

<style scoped>

</style>